<template>
    <ODataLookup :data-object="dsLookupDataObject" :whereClause="getWhereClause" :forceReloadOnOpen="true" :disableRecent="true">
        <template #toolbarActions>
            <slot name="toolbarActions"></slot>
        </template>

        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.slotname" :ref="scope.target" style="cursor: pointer;">
                <slot name="slotname"></slot>
            </span>
        </template>   
        
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="250"></OColumn> 
        <OColumn field="Description" width="250"></OColumn> 
        <OColumn field="OrgUnit" width="250"></OColumn> 
    </ODataLookup>
</template>

<script setup>
    import { computed } from 'vue';
    import { context } from 'o365-modules';
    import { ODataLookup } from 'o365-datalookup';

    const props = defineProps({
        is: String,   
        textInputValue: String,
        textInput: Boolean,
        MetaField: {
            type: String,
            required: true
        },
        DomainID:  {
            type: Number,
            required: false
        }   
    });

    const dsLookupDataObject = $getDataObjectById("dsAssets_MetaObjects");
 
    const metaProp = computed(() => props.MetaField);

    const getWhereClause = computed( () => {
        var vFilter = [];

        if (metaProp.value) {
            vFilter.push("MetaType='" + metaProp.value + "'")
            
            if (props.DomainID) {
                vFilter.push("Domain_ID = " + props.DomainID);
            }   
            else if(context.domainId) {
                vFilter.push("Domain_ID = " + context.domainId); 
            }
            else {
                vFilter.push("1=3");
            }
        }
        else {
            vFilter.push("1=2");
        }

        return vFilter.join(" AND ");
    });
   
</script>